section.intro {
  color: #fff;
  background: linear-gradient(#000 -50%, #332148 90%) 0 0 / cover;
  padding: 1rem;
  position: relative;
  overflow: hidden;
}

@media screen and (width <= 1000px) {
  section.intro {
    padding: .5rem;
  }
}

@media screen and (width <= 400px) {
  section.intro {
    padding: .25rem;
    font-size: .7rem;
  }
}

section.intro .content {
  z-index: 1;
  max-width: 30rem;
  min-height: 45vh;
  margin: 0 auto;
  position: relative;
  overflow: visible;
}

@media screen and (width <= 1000px) {
  section.intro .content {
    max-width: 15rem;
    min-height: 60vh;
  }
}

section.intro .content .raised {
  z-index: 1;
  margin: 1rem;
}

section.intro .content .pills {
  margin: 1em 0;
}

@media screen and (width >= 400px) and (width <= 1000px) {
  section.intro .content .pills {
    margin: .5em 0;
  }
}

@media screen and (width <= 400px) {
  section.intro .content .pills {
    margin: .25em 0;
  }
}

section.intro .decoration {
  transition: transform .5s linear;
  position: absolute;
}

section.intro .decoration:first-child {
  top: 0;
  left: -10%;
}

section.intro .decoration:nth-child(2) {
  top: 40%;
  right: 0;
}

section.intro .decoration:nth-child(3) {
  bottom: 10%;
  right: 40%;
}

section.intro .intro-name {
  margin: 0;
  font-size: 3em;
}

section.intro .intro-name span {
  display: inline-block;
}

@media screen and (width >= 400px) and (width <= 1000px) {
  section.intro .intro-name {
    font-size: 1.6em;
  }
}

@media screen and (width <= 400px) {
  section.intro .intro-name {
    font-size: 1.5em;
  }
}

section.intro .intro-hero {
  margin-bottom: 0;
}

section.intro a.download-pdf {
  color: #332148;
  text-decoration: none;
}

section.intro a.download-pdf i {
  margin-right: .2rem;
  transform: translateY(.05rem)scale(1);
}

section.intro .scroll-invitation {
  position: absolute;
  bottom: 0;
  left: 50%;
}

section.intro .scroll-invitation i.gg-mouse {
  opacity: .5;
  --ggs: 1.5;
  margin-top: 1rem;
}

section.intro .scroll-invitation i.gg-mouse:after {
  animation: 2s infinite lining-down;
}
/*# sourceMappingURL=index.f63b895e.css.map */
