@import "./vars.scss";

section.intro {
  @include adaptive-padding;
  position: relative;
  overflow: hidden;
  background: linear-gradient(black -50%, $secondary 90%);
  background-size: cover;
  color: white;

  @include media(sm) {
    font-size: 0.7rem;
  }

  .content {
    overflow: visible;
    z-index: 1;
    max-width: 30rem;
    min-height: 45vh;
    margin: 0 auto;
    position: relative;

    @include media(lt-md) {
      max-width: 15rem;
      min-height: 60vh;
    }

    .raised {
      margin: 1rem 1rem;
      z-index: 1;
    }

    .pills {
      margin: 1em 0;

      @include media(md) {
        margin: 0.5em 0;
      }

      @include media(sm) {
        margin: 0.25em 0;
      }
    }
  }

  .decoration {
    position: absolute;
    transition: transform 0.5s linear;

    &:nth-child(1) {
      left: -10%;
      top: 0;
    }

    &:nth-child(2) {
      right: 0;
      top: 40%;
    }

    &:nth-child(3) {
      right: 40%;
      bottom: 10%;
    }
  }

  .intro-name {
    margin: 0;
    font-size: 3em;

    span {
      display: inline-block;
    }

    @include media(md) {
      font-size: 1.6em;
    }

    @include media(sm) {
      font-size: 1.5em;
    }

  }

  .intro-hero {
    margin-bottom: 0;
  }

  a.download-pdf {
    color: $secondary;
    text-decoration: none;

    i {
      margin-right: 0.2rem;
      transform: translateY(0.05rem) scale(1);
    }
  }

  .scroll-invitation {
    position: absolute;
    bottom: 0;
    left: 50%;

    i.gg-mouse {
      margin-top: 1rem;
      opacity: 0.5;
      --ggs: 1.5;

      &:after {
        animation: lining-down 2s infinite;
      }
    }
  }
}
